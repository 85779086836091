import React, { Suspense, lazy } from 'react';
import { Spin } from 'antd';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

const UsersIndex = lazy(() => import('../../container/users/Index'));
const UsersProfile = lazy(() => import('../../container/users/Profile'));
const UsersForm = lazy(() => import('../../container/users/Form'));
const UsersView = lazy(() => import('../../container/users/View'));
const UsersReview = lazy(() => import('../../container/users/Review'));
const EmployeesTransfer = lazy(() => import('../../container/users/EmployeesTransfer'));
const NotFound = lazy(() => import('../../container/pages/404'));

const UsersRoute = () => {
  const { path } = useRouteMatch();

  return (
    <Suspense
      fallback={
        <div className="spin">
          <Spin />
        </div>
      }
    >
      <Switch>
        <Route path={`${path}/profile`} component={UsersProfile} />
        <Route path={`${path}/add/:source?`} component={UsersForm} />
        <Route path={`${path}/edit/:id`} component={UsersForm} />
        <Route path={`${path}/view/:id`} component={UsersView} />
        <Route path={`${path}/review/:id`} component={UsersReview} />
        <Route path={`${path}/employees/transfer`} component={EmployeesTransfer} />
        <Route exact path={`${path}/:source?`} component={UsersIndex} />
        <Route component={NotFound} />
      </Switch>
    </Suspense>
  );
};

export default UsersRoute;
