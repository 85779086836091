/* eslint-disable no-unused-vars */
import CONSTANTS from '../constants';

const { ATENDENTE, ESTAGIARIO, EXECUTOR, ANALISTA, SUPERVISOR, COORDENADOR, CLIENTE } = CONSTANTS.PROFILE;
const {
  DESENVOLVIMENTO,
  DIRETORIA,
  FISCAL,
  CONTABIL,
  PESSOAL,
  IMPLANTACAO,
  ARQUIVOS,
  TRIBUTOS,
  CONSULTORIA,
  PARALEGAL,
  RECEPCAO,
  FINANCEIRO,
  RECURSOS_HUMANOS,
  RELACIONAMENTO,
  GESTAO,
  PROJETOS,
  MARKETING,
  AUDITORIA,
  SUPORTE_TI,
} = CONSTANTS.DEPARTMENT;

const rules = {
  // Cache
  '/admin/cache/clearAll': {
    '*': [COORDENADOR, SUPERVISOR],
  },

  /*
   * Achievements
   */
  '/admin/achievements': {
    [GESTAO]: CONSTANTS.COLABORADORES_PROFILES,
    [RECURSOS_HUMANOS]: CONSTANTS.COLABORADORES_PROFILES,
  },
  '/admin/achievements/add': {
    [GESTAO]: CONSTANTS.COLABORADORES_PROFILES,
    [RECURSOS_HUMANOS]: CONSTANTS.COLABORADORES_PROFILES,
  },
  '/admin/achievements/edit/:id': {
    [GESTAO]: CONSTANTS.COLABORADORES_PROFILES,
    [RECURSOS_HUMANOS]: CONSTANTS.COLABORADORES_PROFILES,
  },
  '/admin/achievements/view/:id': {
    [GESTAO]: CONSTANTS.COLABORADORES_PROFILES,
    [RECURSOS_HUMANOS]: CONSTANTS.COLABORADORES_PROFILES,
  },
  '/admin/achievements/delete/:id': {
    [GESTAO]: CONSTANTS.COLABORADORES_PROFILES,
    [RECURSOS_HUMANOS]: CONSTANTS.COLABORADORES_PROFILES,
  },

  /*
   * AchievementProducts
   */
  '/admin/achievementProducts/add': {
    [GESTAO]: CONSTANTS.COLABORADORES_PROFILES,
    [RECURSOS_HUMANOS]: CONSTANTS.COLABORADORES_PROFILES,
  },
  '/admin/achievementProducts/edit/:id': {
    [GESTAO]: CONSTANTS.COLABORADORES_PROFILES,
    [RECURSOS_HUMANOS]: CONSTANTS.COLABORADORES_PROFILES,
  },
  '/admin/achievementProducts/delete/:id': {
    [GESTAO]: CONSTANTS.COLABORADORES_PROFILES,
    [RECURSOS_HUMANOS]: CONSTANTS.COLABORADORES_PROFILES,
  },

  /*
   * Assets
   */
  '/admin/assets': {
    [FINANCEIRO]: CONSTANTS.COLABORADORES_PROFILES,
    [SUPORTE_TI]: CONSTANTS.COLABORADORES_PROFILES,
  },
  '/admin/assets/add': {
    [FINANCEIRO]: CONSTANTS.COLABORADORES_PROFILES,
    [SUPORTE_TI]: CONSTANTS.COLABORADORES_PROFILES,
  },
  '/admin/assets/edit/:id': {
    [FINANCEIRO]: CONSTANTS.COLABORADORES_PROFILES,
    [SUPORTE_TI]: CONSTANTS.COLABORADORES_PROFILES,
  },
  '/admin/assets/view/:id': {
    [FINANCEIRO]: CONSTANTS.COLABORADORES_PROFILES,
    [SUPORTE_TI]: CONSTANTS.COLABORADORES_PROFILES,
  },
  '/admin/assets/delete/:id': {
    [FINANCEIRO]: CONSTANTS.COLABORADORES_PROFILES,
    [SUPORTE_TI]: CONSTANTS.COLABORADORES_PROFILES,
  },

  /*
   * Asset Categories
   */
  '/admin/assetCategories': {
    [FINANCEIRO]: CONSTANTS.COLABORADORES_PROFILES,
    [SUPORTE_TI]: CONSTANTS.COLABORADORES_PROFILES,
  },
  '/admin/assetCategories/add': {
    [FINANCEIRO]: CONSTANTS.COLABORADORES_PROFILES,
    [SUPORTE_TI]: CONSTANTS.COLABORADORES_PROFILES,
  },
  '/admin/assetCategories/edit/:id': {
    [FINANCEIRO]: CONSTANTS.COLABORADORES_PROFILES,
    [SUPORTE_TI]: CONSTANTS.COLABORADORES_PROFILES,
  },
  '/admin/assetCategories/view/:id': {
    [FINANCEIRO]: CONSTANTS.COLABORADORES_PROFILES,
    [SUPORTE_TI]: CONSTANTS.COLABORADORES_PROFILES,
  },
  '/admin/assetCategories/delete/:id': {
    [FINANCEIRO]: CONSTANTS.COLABORADORES_PROFILES,
    [SUPORTE_TI]: CONSTANTS.COLABORADORES_PROFILES,
  },

  /*
   * Cards
   */
  '/admin/cards/clients': {
    '*': [CLIENTE],
  },
  '/admin/cards/view/:id': {
    '*': '*',
  },
  '/admin/cards/interact/:id': {
    '*': '*',
  },
  '/admin/cards/clients/modal/interact/:id/reviews': {
    '*': [CLIENTE],
  },
  '/admin/cards/view/:id/reviews': {
    '*': [CLIENTE],
  },
  '/admin/cards/interact/:id/reviews': {
    '*': [CLIENTE],
  },
  '/admin/cards/add': {
    '*': [COORDENADOR, SUPERVISOR],
    [GESTAO]: CONSTANTS.COLABORADORES_PROFILES,
  },
  '/admin/cards/edit/:id': {
    '*': [COORDENADOR, SUPERVISOR],
    [GESTAO]: CONSTANTS.COLABORADORES_PROFILES,
  },
  '/admin/cards/delete/:id': {
    '*': [COORDENADOR],
    [GESTAO]: CONSTANTS.COLABORADORES_PROFILES,
  },
  '/admin/cards/files': {
    '*': '*',
  },

  /*
   * CardJustificationReasons
   */
  '/admin/cardJustificationReasons': {
    [GESTAO]: CONSTANTS.COLABORADORES_PROFILES,
  },
  '/admin/cardJustificationReasons/add': {
    [GESTAO]: CONSTANTS.COLABORADORES_PROFILES,
  },
  '/admin/cardJustificationReasons/edit/:id': {
    [GESTAO]: CONSTANTS.COLABORADORES_PROFILES,
  },
  '/admin/cardJustificationReasons/view/:id': {
    [GESTAO]: CONSTANTS.COLABORADORES_PROFILES,
  },
  '/admin/cardJustificationReasons/delete/:id': {
    [GESTAO]: CONSTANTS.COLABORADORES_PROFILES,
  },

  /*
   * CardMapViews
   */
  '/admin/cardMapViews/add': {
    '*': [COORDENADOR, SUPERVISOR],
    [GESTAO]: CONSTANTS.COLABORADORES_PROFILES,
  },
  '/admin/cardMapViews/edit/:id': {
    '*': [COORDENADOR, SUPERVISOR],
    [GESTAO]: CONSTANTS.COLABORADORES_PROFILES,
  },
  '/admin/cardMapViews/delete/:id': {
    '*': [COORDENADOR, SUPERVISOR],
    [GESTAO]: CONSTANTS.COLABORADORES_PROFILES,
  },

  /*
   * CardTemplates
   */
  '/admin/cardTemplates': {
    '*': [COORDENADOR, SUPERVISOR],
    [GESTAO]: CONSTANTS.COLABORADORES_PROFILES,
  },
  '/admin/cardTemplates/add': {
    [GESTAO]: CONSTANTS.COLABORADORES_PROFILES,
    users: [
      67, // jessyka.silva@secran.com.br
    ],
  },
  '/admin/cardTemplates/edit/:id': {
    [GESTAO]: CONSTANTS.COLABORADORES_PROFILES,
    users: [
      67, // jessyka.silva@secran.com.br
    ],
  },
  '/admin/cardTemplates/view/:id': {
    '*': [COORDENADOR, SUPERVISOR],
    [GESTAO]: CONSTANTS.COLABORADORES_PROFILES,
  },
  '/admin/cardTemplates/delete/:id': {
    [GESTAO]: CONSTANTS.COLABORADORES_PROFILES,
    users: [
      67, // jessyka.silva@secran.com.br
    ],
  },

  /*
   * Clients
   */
  '/admin/clients': {
    '*': CONSTANTS.COLABORADORES_PROFILES,
  },
  '/admin/clients/view/:id': {
    '*': CONSTANTS.COLABORADORES_PROFILES,
  },
  '/admin/clients/add': {
    [PARALEGAL]: CONSTANTS.COLABORADORES_PROFILES,
    [FINANCEIRO]: CONSTANTS.COLABORADORES_PROFILES,
    [FISCAL]: [EXECUTOR], // Solicitado pelo Orleanderson (Whatsapp)
  },
  // Usado para mostrar formulário
  '/admin/clients/edit': {
    '*': [COORDENADOR, SUPERVISOR],
    [PARALEGAL]: CONSTANTS.COLABORADORES_PROFILES,
    [FINANCEIRO]: CONSTANTS.COLABORADORES_PROFILES,
    [RECEPCAO]: CONSTANTS.COLABORADORES_PROFILES,
  },
  // Usado ao carregar a página
  '/admin/clients/edit/:id': {
    '*': CONSTANTS.COLABORADORES_PROFILES,
    [PARALEGAL]: CONSTANTS.COLABORADORES_PROFILES,
    [FINANCEIRO]: CONSTANTS.COLABORADORES_PROFILES,
    [RECEPCAO]: CONSTANTS.COLABORADORES_PROFILES,
  },
  '/admin/clients/delete/:id': {
    [PARALEGAL]: CONSTANTS.COLABORADORES_PROFILES,
    [FINANCEIRO]: CONSTANTS.COLABORADORES_PROFILES,
  },
  '/admin/clients/edit/:id/cnpj': {
    '*': [COORDENADOR, SUPERVISOR],
    [PARALEGAL]: CONSTANTS.COLABORADORES_PROFILES,
    [FINANCEIRO]: CONSTANTS.COLABORADORES_PROFILES,
  },
  '/admin/clients/edit/:id/address': {
    [PARALEGAL]: CONSTANTS.COLABORADORES_PROFILES,
    [FINANCEIRO]: CONSTANTS.COLABORADORES_PROFILES,
  },
  '/admin/clients/edit/:id/contacts': {
    '*': [COORDENADOR, SUPERVISOR, ANALISTA, ATENDENTE],
    [PARALEGAL]: CONSTANTS.COLABORADORES_PROFILES,
    [RECEPCAO]: CONSTANTS.COLABORADORES_PROFILES,
  },
  '/admin/clients/edit/:id/contracts': {
    [PARALEGAL]: CONSTANTS.COLABORADORES_PROFILES,
    [FINANCEIRO]: CONSTANTS.COLABORADORES_PROFILES,
  },
  '/admin/clients/edit/:id/users': {
    [GESTAO]: CONSTANTS.COLABORADORES_PROFILES,
    [PARALEGAL]: CONSTANTS.COLABORADORES_PROFILES,
    [RECURSOS_HUMANOS]: CONSTANTS.COLABORADORES_PROFILES,
    [FINANCEIRO]: CONSTANTS.COLABORADORES_PROFILES,
    [IMPLANTACAO]: CONSTANTS.COLABORADORES_PROFILES,
  },
  '/admin/clients/edit/:id/employees': {
    '*': [COORDENADOR, SUPERVISOR],
    [PARALEGAL]: CONSTANTS.COLABORADORES_PROFILES,
    [FINANCEIRO]: CONSTANTS.COLABORADORES_PROFILES,
  },
  '/admin/clients/edit/:id/taxations': {
    '*': [COORDENADOR, SUPERVISOR],
    [PARALEGAL]: CONSTANTS.COLABORADORES_PROFILES,
  },
  '/admin/clients/edit/:id/taxBreaks': {
    '*': [COORDENADOR, SUPERVISOR],
    [PARALEGAL]: CONSTANTS.COLABORADORES_PROFILES,
  },
  '/admin/clients/edit/:id/cnae': {
    [PARALEGAL]: CONSTANTS.COLABORADORES_PROFILES,
    [FINANCEIRO]: CONSTANTS.COLABORADORES_PROFILES,
  },
  '/admin/clients/edit/:id/files': {
    '*': ['COORDENADOR'],
    [PARALEGAL]: CONSTANTS.COLABORADORES_PROFILES,
    [FINANCEIRO]: CONSTANTS.COLABORADORES_PROFILES,
    [IMPLANTACAO]: CONSTANTS.COLABORADORES_PROFILES,
  },
  '/admin/clients/edit/:id/passwords': {
    '*': [COORDENADOR, SUPERVISOR],
    [PARALEGAL]: CONSTANTS.COLABORADORES_PROFILES,
    [FISCAL]: CONSTANTS.COLABORADORES_PROFILES,
    [FINANCEIRO]: CONSTANTS.COLABORADORES_PROFILES,
    [IMPLANTACAO]: CONSTANTS.COLABORADORES_PROFILES,
  },
  '/admin/clients/edit/:id/partners': {
    '*': [COORDENADOR, SUPERVISOR],
    [PARALEGAL]: CONSTANTS.COLABORADORES_PROFILES,
  },
  '/admin/clients/edit/:id/partnerActs': {
    '*': [COORDENADOR, SUPERVISOR],
    [PARALEGAL]: CONSTANTS.COLABORADORES_PROFILES,
  },
  '/admin/clients/edit/:id/negativeCertificates': {
    [PARALEGAL]: CONSTANTS.COLABORADORES_PROFILES,
    [FINANCEIRO]: CONSTANTS.COLABORADORES_PROFILES,
  },
  '/admin/clients/edit/:id/attorneys': {
    '*': [COORDENADOR, SUPERVISOR],
    [PARALEGAL]: CONSTANTS.COLABORADORES_PROFILES,
  },
  '/admin/clients/edit/:id/digitalCertificates': {
    [PARALEGAL]: CONSTANTS.COLABORADORES_PROFILES,
    [FINANCEIRO]: CONSTANTS.COLABORADORES_PROFILES,
  },
  '/admin/clients/edit/:id/licenses': {
    [PARALEGAL]: CONSTANTS.COLABORADORES_PROFILES,
    [FINANCEIRO]: CONSTANTS.COLABORADORES_PROFILES,
  },
  '/admin/clients/edit/:id/cardTemplates': {
    '*': [COORDENADOR, SUPERVISOR],
    [GESTAO]: CONSTANTS.COLABORADORES_PROFILES,
  },

  // Files
  '/admin/files': {
    [GESTAO]: CONSTANTS.COLABORADORES_PROFILES,
  },
  '/admin/files/sent': {
    '*': '*',
  },

  /*
   * Tools
   */
  '/admin/tools': {
    '*': [COORDENADOR, SUPERVISOR],
    [GESTAO]: CONSTANTS.COLABORADORES_PROFILES,
    users: [
      18, // bruna.oliveira@secran.com.br
      21, // carlos.albuquerque@secran.com.br
    ],
  },
  '/admin/cardTemplates/build': {
    '*': [COORDENADOR, SUPERVISOR],
    [GESTAO]: CONSTANTS.COLABORADORES_PROFILES,
    users: [
      18, // bruna.oliveira@secran.com.br
      21, // carlos.albuquerque@secran.com.br
    ],
  },
  '/admin/cardTemplates/transfer': {
    '*': [COORDENADOR, SUPERVISOR],
    [GESTAO]: CONSTANTS.COLABORADORES_PROFILES,
  },
  '/admin/clients/copy': {
    '*': [COORDENADOR, SUPERVISOR],
    [GESTAO]: CONSTANTS.COLABORADORES_PROFILES,
  },
  '/admin/clients/transfer': {
    '*': [COORDENADOR, SUPERVISOR],
    [GESTAO]: CONSTANTS.COLABORADORES_PROFILES,
  },
  '/admin/users/employees/transfer': {
    '*': [COORDENADOR, SUPERVISOR],
    [GESTAO]: CONSTANTS.COLABORADORES_PROFILES,
  },

  /* ClientUsers */
  '/admin/clientUsers': {
    '*': [CLIENTE],
  },

  /*
   * Users
   */
  '/admin/users/:source?': {
    '*': CONSTANTS.COLABORADORES_PROFILES,
  },
  '/admin/users/index/:source?': {
    '*': CONSTANTS.COLABORADORES_PROFILES,
  },
  '/admin/users/add/:source?': {
    [GESTAO]: CONSTANTS.COLABORADORES_PROFILES,
    [PARALEGAL]: CONSTANTS.COLABORADORES_PROFILES,
    [RECEPCAO]: CONSTANTS.COLABORADORES_PROFILES,
    [RECURSOS_HUMANOS]: CONSTANTS.COLABORADORES_PROFILES,
    [FINANCEIRO]: CONSTANTS.COLABORADORES_PROFILES,
    [IMPLANTACAO]: CONSTANTS.COLABORADORES_PROFILES,
  },
  '/admin/users/edit/:id': {
    [GESTAO]: CONSTANTS.COLABORADORES_PROFILES,
    [PARALEGAL]: CONSTANTS.COLABORADORES_PROFILES,
    [RECEPCAO]: CONSTANTS.COLABORADORES_PROFILES,
    [RECURSOS_HUMANOS]: CONSTANTS.COLABORADORES_PROFILES,
    [FINANCEIRO]: CONSTANTS.COLABORADORES_PROFILES,
    [IMPLANTACAO]: CONSTANTS.COLABORADORES_PROFILES,
  },
  '/admin/users/delete/:id': {
    [GESTAO]: CONSTANTS.COLABORADORES_PROFILES,
    [PARALEGAL]: CONSTANTS.COLABORADORES_PROFILES,
    [RECURSOS_HUMANOS]: CONSTANTS.COLABORADORES_PROFILES,
    [FINANCEIRO]: CONSTANTS.COLABORADORES_PROFILES,
    [IMPLANTACAO]: CONSTANTS.COLABORADORES_PROFILES,
  },

  '/admin/users/review/:id': {
    '*': [CLIENTE],
  },
  '/admin/users/review/:id/add': {
    '*': [CLIENTE],
  },
  '/admin/users/review/:id/edit/:id': {
    '*': [CLIENTE],
  },

  /*
   * Economic Groupsv
   */
  '/admin/economicGroups': {
    '*': CONSTANTS.COLABORADORES_PROFILES,
  },
  '/admin/economicGroups/add': {
    '*': [COORDENADOR, SUPERVISOR],
    [GESTAO]: CONSTANTS.COLABORADORES_PROFILES,
    users: [
      67, // jessyka.silva@secran.com.br
    ],
  },
  '/admin/economicGroups/edit/:id': {
    '*': [COORDENADOR, SUPERVISOR],
    [GESTAO]: CONSTANTS.COLABORADORES_PROFILES,
    users: [
      67, // jessyka.silva@secran.com.br
    ],
  },
  '/admin/economicGroups/delete/:id': {
    '*': [COORDENADOR, SUPERVISOR],
    [GESTAO]: CONSTANTS.COLABORADORES_PROFILES,
    users: [
      67, // jessyka.silva@secran.com.br
    ],
  },

  /*
   * File Categories
   */
  '/admin/fileCategories': {
    '*': [COORDENADOR, SUPERVISOR],
    [GESTAO]: CONSTANTS.COLABORADORES_PROFILES,
  },
  '/admin/fileCategories/add': {
    '*': [COORDENADOR, SUPERVISOR],
    [GESTAO]: CONSTANTS.COLABORADORES_PROFILES,
  },
  '/admin/fileCategories/edit/:id': {
    '*': [COORDENADOR, SUPERVISOR],
    [GESTAO]: CONSTANTS.COLABORADORES_PROFILES,
  },
  '/admin/fileCategories/delete/:id': {
    '*': [COORDENADOR, SUPERVISOR],
    [GESTAO]: CONSTANTS.COLABORADORES_PROFILES,
  },

  /*
   * File Categories
   */
  '/admin/queuedJobs': {
    '*': [COORDENADOR, SUPERVISOR],
    [GESTAO]: CONSTANTS.COLABORADORES_PROFILES,
  },
  '/admin/queuedJobs/add/:template': {
    '*': [COORDENADOR, SUPERVISOR],
    [GESTAO]: CONSTANTS.COLABORADORES_PROFILES,
  },
  '/admin/queuedJobs/edit/:id': {
    '*': [COORDENADOR, SUPERVISOR],
    [GESTAO]: CONSTANTS.COLABORADORES_PROFILES,
  },
  '/admin/queuedJobs/delete/:id': {
    '*': [COORDENADOR, SUPERVISOR],
    [GESTAO]: CONSTANTS.COLABORADORES_PROFILES,
  },

  /*
   * Newsletters
   */
  '/admin/newsletters': {
    [GESTAO]: CONSTANTS.COLABORADORES_PROFILES,
    [RECURSOS_HUMANOS]: CONSTANTS.COLABORADORES_PROFILES,
    [MARKETING]: CONSTANTS.COLABORADORES_PROFILES,
  },
  '/admin/newsletters/add': {
    [GESTAO]: CONSTANTS.COLABORADORES_PROFILES,
    [RECURSOS_HUMANOS]: CONSTANTS.COLABORADORES_PROFILES,
    [MARKETING]: CONSTANTS.COLABORADORES_PROFILES,
  },
  '/admin/newsletters/edit/:id': {
    [GESTAO]: CONSTANTS.COLABORADORES_PROFILES,
    [RECURSOS_HUMANOS]: CONSTANTS.COLABORADORES_PROFILES,
    [MARKETING]: CONSTANTS.COLABORADORES_PROFILES,
  },
  '/admin/newsletters/delete/:id': {
    [GESTAO]: CONSTANTS.COLABORADORES_PROFILES,
    [RECURSOS_HUMANOS]: CONSTANTS.COLABORADORES_PROFILES,
    [MARKETING]: CONSTANTS.COLABORADORES_PROFILES,
  },
};

export default rules;
