const actions = {
  REVIEWS_UPDATE_BEGIN: 'REVIEWS_UPDATE_BEGIN',
  REVIEWS_UPDATE_SUCCESS: 'REVIEWS_UPDATE_SUCCESS',
  REVIEWS_UPDATE_ERR: 'REVIEWS_UPDATE_ERR',

  reviewsUpdateBegin: () => {
    return {
      type: actions.REVIEWS_UPDATE_BEGIN,
    };
  },

  reviewsUpdateSuccess: data => {
    return {
      type: actions.REVIEWS_UPDATE_SUCCESS,
      data,
    };
  },

  reviewsUpdateErr: err => {
    return {
      type: actions.REVIEWS_UPDATE_ERR,
      err,
    };
  },

  REVIEWS_CLEANUP_BEGIN: 'REVIEWS_CLEANUP_BEGIN',
  REVIEWS_CLEANUP_SUCCESS: 'REVIEWS_CLEANUP_SUCCESS',
  REVIEWS_CLEANUP_ERR: 'REVIEWS_CLEANUP_ERR',

  reviewsCleanupBegin: () => {
    return {
      type: actions.REVIEWS_CLEANUP_BEGIN,
    };
  },

  reviewsCleanupSuccess: data => {
    return {
      type: actions.REVIEWS_CLEANUP_SUCCESS,
      data,
    };
  },

  reviewsCleanupErr: err => {
    return {
      type: actions.REVIEWS_CLEANUP_ERR,
      err,
    };
  },

  REVIEW_UPDATE_BEGIN: 'REVIEW_UPDATE_BEGIN',
  REVIEW_UPDATE_SUCCESS: 'REVIEW_UPDATE_SUCCESS',
  REVIEW_UPDATE_ERR: 'REVIEW_UPDATE_ERR',

  reviewUpdateBegin: () => {
    return {
      type: actions.REVIEW_UPDATE_BEGIN,
    };
  },

  reviewUpdateSuccess: data => {
    return {
      type: actions.REVIEW_UPDATE_SUCCESS,
      data,
    };
  },

  reviewUpdateErr: err => {
    return {
      type: actions.REVIEW_UPDATE_ERR,
      err,
    };
  },

  REVIEW_CLEANUP_BEGIN: 'REVIEW_CLEANUP_BEGIN',
  REVIEW_CLEANUP_SUCCESS: 'REVIEW_CLEANUP_SUCCESS',
  REVIEW_CLEANUP_ERR: 'REVIEW_CLEANUP_ERR',

  reviewCleanupBegin: () => {
    return {
      type: actions.REVIEW_CLEANUP_BEGIN,
    };
  },

  reviewCleanupSuccess: () => {
    return {
      type: actions.REVIEW_CLEANUP_SUCCESS,
    };
  },

  reviewCleanupErr: err => {
    return {
      type: actions.REVIEW_CLEANUP_ERR,
      err,
    };
  },

  REVIEW_SAVE_BEGIN: 'REVIEW_SAVE_BEGIN',
  REVIEW_SAVE_SUCCESS: 'REVIEW_SAVE_SUCCESS',
  REVIEW_SAVE_ERR: 'REVIEW_SAVE_ERR',

  reviewSaveBegin: () => {
    return {
      type: actions.REVIEW_SAVE_BEGIN,
    };
  },

  reviewSaveSuccess: data => {
    return {
      type: actions.REVIEW_SAVE_SUCCESS,
      data,
    };
  },

  reviewSaveErr: err => {
    return {
      type: actions.REVIEW_SAVE_ERR,
      err,
    };
  },

  REVIEW_DELETE_BEGIN: 'REVIEW_DELETE_BEGIN',
  REVIEW_DELETE_SUCCESS: 'REVIEW_DELETE_SUCCESS',
  REVIEW_DELETE_ERR: 'REVIEW_DELETE_ERR',

  reviewDeleteBegin: () => {
    return {
      type: actions.REVIEW_DELETE_BEGIN,
    };
  },

  reviewDeleteSuccess: data => {
    return {
      type: actions.REVIEW_DELETE_SUCCESS,
      data,
    };
  },

  reviewDeleteErr: err => {
    return {
      type: actions.REVIEW_DELETE_ERR,
      err,
    };
  },
};

export default actions;
